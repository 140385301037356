/* Documentation */
/*
| Exclude elements height and excludeAmt will be added !!
| there must always be a next element to that does not reach scroll top, fix this later, I dont see why this | should be the case
*/

(function($){
    $.paraScroll = function(el, options){
        var base = this;
        
        base.$el = $(el);
        base.el = el;
        
        base.$el.data("paraScroll", base);

        base.scrolling = false;
        base.hashUrl = null;
        base.scrollElm = null;

        
        base.init = function(){
            base.options = $.extend({},$.paraScroll.defaultOptions, options); 
            
            setTimeout(function(){                    
                base.load();
            }, 500);

            base.bindings();  

            $.paraScroll.initialized = true;         
        };

        base.bindings = function(){

            if(!$.paraScroll.isInit()){
                $(window).bind("load", function(){  

                    if(typeof base.options.onInit == 'function'){
                        base.options.onInit(function(){                  
                            base.load();
                        });
                    }else{
                        base.load();
                    }
                });
            };         

            base.$el.click(function(e){
                e.preventDefault();

                base.hashUrl = base.$el.attr('href'); 
                base.onClick();
                
            });                        
               
            $(window).on('scroll', base.onScroll());
            
        };

        base.load = function(){
            base.hashUrl = location.hash.substring(1);
            if(base.hashUrl){
               
                    base.validateHash();
                    base.highlightNav();
                    base.scroll();
                
            }
            
        };


        base.onScroll = function(){ 
        //If Load or Scroll Function is executed, dont run onScroll
        if(base.scrolling){return false;}          

            return asyncProxy(function (event) {               

                    //Get current Element based on hash this will handle errors that come from there not being a prev elm at any scroll point
                    base.hashUrl = location.hash.substring(1);
                    //base.validateHash(); // there is no point in validating the Hash on scroll
                    // if has URL is not there
                    if(base.hashUrl == "" || base.hashUrl == undefined || base.hashUrl == null){
                        var firstBlock = $("body").find("[data-parascroll-block]:visible").first().attr("data-parascroll-block");     
                        //if first block dosent have parascroll-block set one to home
                        if(firstBlock == "" || firstBlock == undefined || firstBlock == null){
                            // $("body").find("[data-parascroll-block]").first().attr("data-parascroll-block", 'home');
                            base.hashUrl = "home";                              
                        }else{
                           
                           base.hashUrl = firstBlock;
                           if(base.options.showHomeHash){
                               window.location.hash = base.hashUrl;
                           }
                        }
                        
                    }

                    base.scrollElm = $("body").find("[data-parascroll-block="+base.hashUrl+"]");

                    //Get current sroll point
                    var windowScrollTop = $(window).scrollTop();                

                    //Get current and next elm offset
                    var currentElmOffset = base.getOffsetTop(base.scrollElm);

                    // as this is not validated, there must always be a last element that does not pass scroll top
                    var nextElmOffset = base.getOffsetTop(base.scrollElm.next("[data-parascroll-block]:visible"));
                    
           
                    //if you have passed the next element
                    if(windowScrollTop > nextElmOffset ){
                        window.location.hash = base.scrollElm.next("[data-parascroll-block]:visible").attr("data-parascroll-block");
                        base.highlightNav();
                    //if you have passed th prev element                      
                    }else if(windowScrollTop < currentElmOffset){
                        window.location.hash = base.scrollElm.prev("[data-parascroll-block]").attr("data-parascroll-block");
                        base.highlightNav();
                    //Keep Updating the Current highlight                        
                    }else{
                        base.highlightNav();
                    }


            }, {
                delay: 250
            });

        };

        base.scroll = function(){ 
            base.scrolling = true;

            $("html, body").stop().animate({scrollTop:base.scrollAmt()}, base.options.speed, function(){
                base.scrolling = false;
                base.options.onComplete(base.el);
            });
            
        };


        base.scrollAmt  = function(){
            base.scrollElm = $("body").find("[data-parascroll-block="+base.hashUrl+"]:visible");  
            return base.getOffsetTop(base.scrollElm) + 1;       
        };

        base.exclude = function(){
            var excludeElm = 0;            
            
            if(base.options.excludeElm != "" && base.options.excludeElm != null && base.options.excludeElm.length){
                excludeElm = $(base.options.excludeElm).outerHeight(true)
            }
            return base.options.excludeAmt + excludeElm;

        };

        base.getOffsetTop = function(elm){
            return elm.offset().top -  base.exclude();
        };
        
        base.validateHash = function(){
            var hash = location.hash.substring(1);
            if(!$("body").find("[data-parascroll-block="+hash+"]").length){
                console.log('Could not find an element to match the hash');
                window.location.hash= "home";
                base.load();
            }
        };

        base.onClick = function(){
            if(typeof base.options.onInit == 'function'){
                base.options.onInit(function(){
                    window.location.hash = base.hashUrl;  
                    base.highlightNav();          
                    base.scroll();                
                });
            }else{
                // window.location.hash = base.hashUrl;  
                base.highlightNav();          
                base.scroll();      
            }
           
        };

        base.highlightNav = function(){
            if(base.hashUrl != undefined){
                $("."+base.options.activeNavClass).removeClass(base.options.activeNavClass);
                $("body").find("[href="+base.hashUrl+"]").addClass(base.options.activeNavClass);
            }

            /*if(base.options.lastHeightlightElm.length){
                if($(window).scrollTop() + $(window).height() == $(document).height()) {
                    //add hightlight class to a element given in the options
                    if(!$(base.options.lastHeightlightElm).hasClass(base.options.activeNavClass)){
                        $(base.options.lastHeightlightElm).addClass(base.options.activeNavClass);
                    }
                }
            }*/
            
            
        };
        
        // Run initializer
        base.init();
    };

    $.paraScroll.initialized = false;

    $.paraScroll.isInit = function(){
        return $.paraScroll.initialized;
    };
    
    $.paraScroll.defaultOptions = {
        speed: 800,
        excludeElm: null,
        excludeAmt: 0,
        activeNavClass: 'active',
        showHomeHash: false,
        onComplete: function(){},
        onInit: null,
    }
                
    $.fn.paraScroll = function(options){
        return this.each(function(){
            (new $.paraScroll(this, options));
        });
    };
    
})(jQuery);