/* 
## Expander JS options
        #speed: 800, | Int time
        #parent: false, | If useing a parent child relationship to find the expanding element
        #expandElm: null, | element ID or Class selector for element that Expands
        #toggle: false, | If expantion needs to run as toggle 
        #viewPortRange: [],
            If there is a specific Viewport rang in which the epander must work. 
            Array for min and max viewport as int
            if NOT given expander will work accross all viewports and will use the value in defaultVisibility
            when the viewport is changed

        #defaultVisibility: false,
            Boolean
            What to do if view port is changed when expander is not viewport range or if range is not spcified
            True: show expandElm
            False: hide expandElm

        #viewPortDafaultVisibility: false,
            Boolean
            What to do if view port is changed when expander is in viewport range
            True: show expandElm
            False: hide expandElm

        #collapseBtn: null,
            element ID or Class selector for element to be clicked to collapse the expanded element

*/


(function($){
    $.expander = function(el, options){
        var base = this;
        
        base.$el = $(el);
        base.el = el;
        
        base.$el.data("expander", base);
        base.windowWidth = 0;
        
        base.init = function(){
            base.options = $.extend({},$.expander.defaultOptions, options);
            base.sortArray()
            base.bindings();  
            base.windowWidth = window.innerWidth;             
        };

        base.bindings = function(){
        	$(base.$el).click(function(){
                if(base.options.collapseOthers){
                    //console.log(base.options.collapseOthers);
                    base.collapseOthers();
                }
        		base.expand();	        	
        	});

        	$(base.options.collapseBtn).click(function(){
				 base.collapse();       	
        	});

            $(window).resize(function(){

                var newWindowWidth = window.innerWidth;

                if (base.options.widthChangeOnly) {
                    if(base.windowWidth != newWindowWidth){
                        base.windowWidth = newWindowWidth
                        base.defaultVisibility();
                    }
                }else{
                    base.defaultVisibility(); 
                }

            });//resize function

            $(function(){
                base.defaultVisibility();
            });
        };


        base.expand = function(){
            if(base.viewPortRange()){
                base.validateAndExpand();
            	
        	}else if(base.viewPortRange() == false){        		
                base.validateAndExpand();
                         	
            }
        };

        base.validateAndExpand = function(){
            if(base.options.parent){
                    if(base.options.toggle){
                        base.$el.parents(base.options.parent).find(base.options.expandElm).stop().slideToggle(base.options.speed);
                        base.$el.toggleClass(base.options.addClass);
                    }else{
                        base.$el.parents(base.options.parent).find(base.options.expandElm).stop().slideDown(base.options.speed);
                        base.$el.addClass(base.options.addClass);
                    }  
                }else{

                    if(base.options.toggle){
                        $(base.options.expandElm).stop().slideToggle(base.options.speed);
                        base.$el.toggleClass(base.options.addClass);
                    }else{
                        $(base.options.expandElm).stop().slideDown(base.options.speed);
                        base.$el.removeClass(base.options.addClass);
                    }  
                }

        };

        base.collapse = function(){
            // should the collapse function check for parent, it cant be parent right ?

	    	if(base.options.parent){
    			$(base.$el).parents(base.options.parent).find(base.options.expandElm).stop().slideUp(base.options.speed);
                $(base.$el).removeClass(base.options.addClass);	    	        		
	    	}else{        		
    			$(base.options.expandElm).stop().slideUp(base.options.speed);  
                $(base.$el).removeClass(base.options.addClass);
	    	}
        };

        base.collapseOthers = function(){     
            $(base.options.expandElm).stop().slideUp(base.options.speed);
            if(!base.$el.hasClass(base.options.addClass)){
                $("."+base.options.addClass).removeClass(base.options.addClass);
            }
        };

        base.viewPortRange = function(){
            var viewPortRange = base.options.viewPortRange;
            if (viewPortRange === undefined || viewPortRange.length == 0) {
                return false;
            }

            var windowWidth = window.innerWidth;
            if(windowWidth >= viewPortRange[0] && windowWidth <= viewPortRange[1]){
                return true;
            }
        };

        base.defaultVisibility = function(){
            if(base.viewPortRange()){                
                if(base.options.viewPortDafaultVisibility){
                    base.showExpandElm();
                    $(base.$el).addClass(base.options.addClass);
                }else{
                    base.hideExpandElm();
                    $(base.$el).removeClass(base.options.addClass);
                }
            }else{
                if(base.options.defaultVisibility){
                    base.showExpandElm();
                    $(base.$el).addClass(base.options.addClass);
                }else{
                    base.hideExpandElm();
                    $(base.$el).removeClass(base.options.addClass);
                }
            }        
        };

        base.showExpandElm = function(){
            if(base.options.parent){
                $(base.$el).parents(base.options.parent).find(base.options.expandElm).show();
            }else{
                $(base.options.expandElm).show();
            }
        };

        base.hideExpandElm = function(){
            if(base.options.parent){
                $(base.$el).parents(base.options.parent).find(base.options.expandElm).hide();
            }else{
                $(base.options.expandElm).hide();
            }
        };

        base.sortArray = function(){
            base.options.viewPortRange.sort(function(a, b){
                return a - b;               
            });
        };
        

        
        // Run initializer
        base.init();
    };
    
    $.expander.defaultOptions = {
    	speed: 800,
    	parent: false,
    	expandElm: null,
    	toggle: false,
        viewPortRange: [],
        viewPortDafaultVisibility: false,
        defaultVisibility: false,
    	collapseBtn: null,
        addClass: "opened",
        collapseOthers: false,
        widthChangeOnly: true
    };
    
    $.fn.expander = function(options){
        return this.each(function(){
            (new $.expander(this, options));
        });
    };
    
})(jQuery);