var device = {

    mobileScreen: 980,

    isMobile: function(){
        $('body').addClass('mobile').removeClass('desktop');
    },

    isDesktop: function(){
        $('body').addClass('desktop').removeClass('mobile');
    },

    setDevice: function(){
        var windowWidth = window.innerWidth;

        if(windowWidth < device.mobileScreen){
            device.isMobile();
        }else{
            device.isDesktop();
        }
    }

};

 
var header = {
    header: null,
    headerHeight: null,
    headerPlacer: null,
    headerFull: 100,
    headerMin: 80,


    init: function(){
        header.header = $(".header");
        header.headerHeight = $(".header").height();
        header.headerPlacer = $(".header-placer");
        //console.log(header.header);
    },

    setPlacer: function(){
        header.init();
        header.headerPlacer.height(header.headerHeight);
    },

    setHeight: function(){

        var scrollPoint = $(window).scrollTop();

        if(scrollPoint > 10){
            //header.header.height(header.headerMin);
            header.header.stop().animate({height:header.headerMin}, 100);
            header.header.removeClass('fullSize');
        }else{
            header.header.stop().animate({height:header.headerFull}, 100);
           // header.header.height(header.headerFull);
            header.header.addClass('fullSize');
        }
    },
    
};


var mobileNav = {
    speed: 200,
    siteWrap : null,
    navWidth : null,
    scrollPoint : null,
    lastScrollPoint : 0,
    toggleBtnOffset: 5.5,

    collapseCallback: null,

    init: function(){
        mobileNav.siteWrap = $(".primary-wrap");
        mobileNav.navWidth = $(".mobile-nav").width();        
        mobileNav.scrollPoint = $(window).scrollTop();    
    },

    expand: function(elm){
        mobileNav.lastScrollPoint = mobileNav.scrollPoint;
        
        var offsetLeft = -Math.abs(mobileNav.navWidth);        
        var offsetTop = -Math.abs(mobileNav.scrollPoint);
        
        elm.addClass("Open");

        // $(".hamburger").css('margin-right',mobileNav.toggleBtnOffset+'%');
        mobileNav.siteWrap.addClass("fixed").css('top', offsetTop);
        mobileNav.siteWrap.animate({"left": offsetLeft}, mobileNav.speed);
    },

    collapse: function(elm, callback){
        elm.removeClass("Open");

        // $(".hamburger").css('margin-right', 0);
        mobileNav.siteWrap.removeClass("fixed").css('top', 0);

        if(typeof callback == 'function'){
            mobileNav.siteWrap.animate({"left": 0}, mobileNav.speed, function(){
                callback();
            });
        }else{
            mobileNav.siteWrap.animate({"left": 0}, mobileNav.speed);
        }


        $(window).scrollTop(mobileNav.lastScrollPoint);
    },
};

var map = {

    overlay: $(".map-overlay"),
    parent: $(".map-overlay").parents(".map-wrap"),
    activeClass: "map-enabled",

    enable: function(){
        map.overlay.fadeOut("slow");
        map.parent.addClass(map.activeClass);
    },

    disable: function(){
        if((map.parent.hasClass(map.activeClass)) && (!map.parent.find('iframe').is(':focus'))){
            map.overlay.fadeIn("slow");
            map.overlay.parents(".map-wrap").removeClass(map.activeClass);  
        } 
    },
}

var assignHeight = {

    run: function(){
        $("[data-setHeight-from]").each(function(i, e){
            var from = $(e).attr("data-setHeight-from");
            var fromHeight = $(e).outerHeight(true);
            $("[data-setHeight-to='"+from+"']").innerHeight(fromHeight);
        });
    },
    
};

//All Resize function can come here

var processWindowResize = asyncProxy(function (event) {

  	device.setDevice();
    mobileNav.init();
    header.setPlacer();
    assignHeight.run();

}, {
  delay: 250
});


//All Scroll function can come here

var processWindowScroll = asyncProxy(function (event) {
    
    mobileNav.init();
    header.setPlacer(); 
    map.disable();
    //header.setHeight(); //should I just run this on the parallax plugin ?

}, {
  delay: 250
});

$(window).on('resize', processWindowResize);

$(window).on('scroll', processWindowScroll);




// Ready Function
$(function(){

    var windowWidth = window.innerWidth;
	device.setDevice();
    mobileNav.init();
    header.setPlacer();
    assignHeight.run();

    $(window).load(function(){
        header.setPlacer();
        assignHeight.run();
    });

    /*reqwite this there should be no random resize*/
    $(window).resize(function(){
        var newWidth = window.innerWidth;

        if(windowWidth != newWidth){
            mobileNav.collapse($(".hamburger"));
            windowWidth = newWidth;
        }
    });
	
	var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;      
	if(iOS){
	    $("*").css('cursor','pointer');
	}

    $(".hamburger").click(function(e){
        e.preventDefault();

        var elm = $(this);

        if(elm.hasClass("Open")){
            mobileNav.collapse(elm);
        }else{
            mobileNav.expand(elm);
        }
    
    });  

    $(".map-overlay").click(function(e){
        e.stopPropagation();
        e.preventDefault();
        map.enable();
    });

    $(document).click(function(){map.disable();});

/*    $(".ventureTile").contentExpander({
        parent: ".wrapper",
        thisClass: "ventureTile",
        infoContainer: ".venture-info-wrap",
        parentAddClass: "parent-open",
        closeOther: true,
        closeBtn: '.Iclose',
    });
*/
    $(".subsi-block").contentExpander({
        parent: ".wrapper",
        thisClass: "subsi-block",
        infoContainer: ".subsi-info-wrap",
        parentAddClass: "parent-open",
        closeOther: true,
        closeBtn: '.Iclose',
    });


    $(".connect-block").contentExpander({
        parent: ".wrapper",
        thisClass: "connect-block",
        infoContainer: ".connect-info-wrap",
        parentAddClass: "parent-open",
        closeOther: true,
        closeBtn: '.Iclose',
    });

    $(".bod").expander({
        expandElm: '.bodExpand',
        toggle: true,
    });

    /*$(".corpState").expander({
        expandElm: '.corpStateExpand',
        toggle: true,
    });*/


    $(".triggerContent").click(function(){
    
        var dataId = $(this).attr('data-id');

        console.log($("[data-info-block="+dataId+"]"));

        $("[data-info-id="+dataId+"]").trigger("click");
    
    });

    $(".about-director-tumb").colorbox({inline:true});

    $(".paraScroll").paraScroll({
        excludeElm: ".header", 
        onComplete: function(){
            //alert("boo")
        }, 

        onInit: function(cb){
            var toggleBtn = $(".hamburger");
            if(toggleBtn.hasClass("Open")){
                mobileNav.collapse(toggleBtn, function(){cb();});  
            }else{
                cb();
            }
        }, 

    });

// the scroll point is assigned by the backend,  take the class of the linked block and assign it as a ID for the banner slide or vice versa
    $("[data-id='subsidiariesScroll']").Dscroll({
        endPoint: ".subsidiariesScroll",
        exclude: ".header"
    });    
    
    $("[data-id='connectScroll']").Dscroll({
        endPoint: ".connectScroll",
        exclude: ".header"
    }); 



   $("[data-id='aInternationalScroll']").Dscroll({
        endPoint: ".aInternationalScroll",
        exclude: ".header"
    });


    $("[data-id='aEngineeringScroll']").Dscroll({
        endPoint: ".aEngineeringScroll",
        exclude: ".header"
    });


    $("[data-id='aNetworksScroll']").Dscroll({
        endPoint: ".aNetworksScroll",
        exclude: ".header"
    });


    $("[data-id='aTravelsScroll']").Dscroll({
        endPoint: ".aTravelsScroll",
        exclude: ".header"
    });




    $(".chosen-select").chosen({
        width: "100%",
        display_disabled_options:false
    });

    if (!$('.chosen-container').length > 0) {
       $("#countrySelect").find("option").first().text("Choose a Country...");
    }

    $("#contactForm").validate({
        submitBtn: ".contact-submit",
        scrollExclude: 100,
        appendErrorBox: 'after',

        customGlobalMessage: function(e, status){
            if (!($.inArray(false, status) == -1)){
                $(".main-error").fadeIn("slow");
            }else{
                $(".main-error").fadeOut("slow");
            }

            setTimeout(function(){ $(".main-error").fadeOut("slow"); }, 3000);
        },

        // customErrorHandle: function(e, status, defaultError){
            
        // },

        onSubmit: function(){
            //submit form here
            $('#contactForm').xreq({
                url: window.APP.formUrl,
                onSuccess:function(){
                    $(".success").fadeIn("slow");
                    setTimeout(function(){ $(".success").fadeOut("slow"); }, 3000);
                    $("form")[0].reset();
                    $('.chosen-select').val('').trigger('chosen:updated');
                },
                onError:function(){
                    alert('Validation errors');
                }
            });
        },

    });

});//JQ


