/* Documentation */
/*
The Element that is appended must be diffrent from the grid as it will change the ordering of :nth elements
1. parent - what is the parent
2. infoContainer - the wrapper that holds the TPL DOMs
3. thisClass - The common class that is in a grid Item (generally the class that is initialized)
must be definded or you will get a error

The plugin has a dependancy on Throttle.js
*/

(function($){
    $.contentExpander = function(el, options){
        var base = this;
        
        base.$el = $(el);
        base.el = el;
        
        base.$el.data("contentExpander", base);

        base.windowWidth = window.innerWidth; 

        base.wrapper = null;
        base.wrapperWidth = 0;
        base.elmWidth = null;
        base.elmIndex = null;
        base.elmPerRow = null;
        base.domId = null;

        
        base.init = function(){
            base.options = $.extend({},$.contentExpander.defaultOptions, options);
            $(base.options.infoContainer).children().hide();
            base.wrapper = base.$el.parents(base.options.parent);
            base.elmIndex = base.$el.index();
          	base.bindings();
        };


        base.bindings = function(){
        	$(base.$el).click(function(e){  
                e.preventDefault();                

                if(!base.wrapper.find("[data-info-block="+base.$el.attr("data-info-id")+"]").is(":animated")){
                    base.setVar();
                    base.showContent();
                    // base.scrollTo(base.$el);  
                }
	        		
        	});

        	$(window).on('resize', base.resize());

            $(base.options.closeBtn).click(function(){
                base.wrapper.removeClass(base.options.parentAddClass); 
                $(base.$el).removeClass(base.options.addClass);
                base.slideUp(base.domId);
            });
        	
        };

/*        base.scrollTo = function(elm){
            var amt = elm.offset().top;            
            $("html, body").stop().animate({scrollTop:amt}, 10);
        };*/

        base.resize = function(){
        	return asyncProxy(function (event) {
        		if(base.windowWidth != window.innerWidth){
	        		base.setVar();
	    			base.rest(base.domId);
	    			base.windowWidth = window.innerWidth;
    			}

        	}, {
        		delay: 250
        	});
        };

        base.setVar = function(){        	

	        base.wrapperWidth = base.wrapper.width();
	        base.elmWidth = base.$el.width();

	        
	        base.elmPerRow = parseInt(base.wrapperWidth/base.elmWidth);
        };

        base.skipCount = function(){
        	var skipCount = base.elmPerRow - (base.elmIndex % base.elmPerRow)-1;
            var nextElmCount = base.$el.nextAll("."+base.options.thisClass).length;
            
            if(isNaN(skipCount)){
                skipCount = 0;
            }

            if(skipCount > nextElmCount){
                skipCount = nextElmCount;
            }
            

        	return skipCount;
        };

        base.getDom = function(){
        	base.domId = base.$el.attr("data-info-id");
        	var dom = $(base.options.infoContainer).find("[data-info-block="+base.domId+"]");
        	return dom;
        };

        base.appendDom = function(){
        	var lastElm = base.elmIndex+base.skipCount();
        	base.getDom().insertAfter(base.wrapper.find("."+base.options.thisClass) [lastElm]); 
        };

        base.slideDown = function(domId){
        	base.wrapper.find("[data-info-block="+domId+"]").stop(false, false).slideDown(base.options.speed);                      
        }

        base.slideUp = function(domId){
            var domElm = base.wrapper.find("[data-info-block="+domId+"]");
            domElm.stop(false, false).slideUp(base.options.speed, function(){
                $(base.options.infoContainer).append(domElm);
            });          
        }

        base.slideUpAll = function(){
        	base.wrapper.find("[data-info-block]").stop().slideUp(base.options.speed, function(){
                $(base.options.infoContainer).append($(this));
            });
        }

        base.rest = function(domId){
        	$(base.options.infoContainer).append(base.wrapper.find("[data-info-block="+domId+"]"));
        	$("."+base.options.thisClass).removeClass(base.options.addClass);
            base.wrapper.removeClass(base.options.parentAddClass); 
        }
        
        base.showContent = function(){
        	if(!$(base.$el).hasClass(base.options.addClass)){

        		if(base.options.closeOther){        		
        			base.slideUpAll();
                    base.wrapper.find("[data-info-block]").stop().slideUp(base.options.speed, function(){
                        $(base.options.infoContainer).append($(this));                        
                    });

        			base.wrapper.find($("."+base.options.thisClass).removeClass(base.options.addClass));
        		}

                $(base.$el).addClass(base.options.addClass);                
        		base.wrapper.addClass(base.options.parentAddClass);        		

        		base.appendDom();

                

                setTimeout(base.scrollTo, (base.options.speed - 100));


        		base.slideDown(base.domId);
        	}else{
                base.wrapper.removeClass(base.options.parentAddClass); 
        		$(base.$el).removeClass(base.options.addClass);
        		base.slideUp(base.domId);
        	}
        	
        };


        base.scrollTo = function(){
            if(base.options.scrollToElm){
                var amt = (base.$el.offset().top) - (base.options.scrollExclude);            
                $("html, body").stop().animate({scrollTop:amt});
            }
        };

        // Run initializer
        base.init();
        
    };
    
    $.contentExpander.defaultOptions = {
    	speed: 500,
    	parent: null,
    	infoContainer: null,
    	thisClass: null,
    	closeOther: false,
    	addClass: 'open',
        scrollToElm: true,
        scrollExclude: 100,
        parentAddClass: 'open',
        closeBtn: 'close-info',
    	//speed: 800,
    	//distance: null
    };
    
    $.fn.contentExpander = function(options){
        return this.each(function(){
            (new $.contentExpander(this, options));
        });
    };
    
})(jQuery);