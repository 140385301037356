/*

*** THIS IS THE ONLY DOCUMENTATION ON THE PLUGIN KEEP IT UP TO DATE

-- Initalize the plugin for the form containing the fields 
-- Every type of validation must be sepcified in data-val-type tags
 - data-val-type='["phone"]'

-- Default errors are there but the user can pass custom error messages through the data-val-error tag 
 - data-val-error="Please fill in your name"

-- If mobile can scroll to problamatic element (IF mobile ?)

-- The plugin will always add a success and error class, you can define what they are as options
 - errorClass:, successClass:

-- by default if no external is found will show the error message at the top or bottom of element 
 - appendErrorBox: 'before | after'

-- custom regex can be passed if there is a need through data-val-rgx attr 

-- what to do if error can be a external function

-- The form will not submit with the plugin, the submittion must be manually written at initiation
 - $("#contactForm").validate({
        onSubmit: function(){
            //submit form here
            $(this).submit();
        }
    });

-- as the submition must be done on the userend validaing things like chosen can be done there (drop downs dont need blurs there as can either be an action or no action only)
-- custom css can be passed into the default error box as options if needed

-- The Custom Global Message functions gets the error element and the current validity status the plugin is maintaining
 - $("#contactForm").validate({
        customGlobalMessage: function(e, status){
            //you can use the data from the elemenet and use the status do to stuff outsde the plugin
        },
    });

-- The Custom Error Handle functions gets the error element and the current validity status the plugin is maintaining and the relavant defaul message
 - $("#contactForm").validate({
       customGlobalMessage: function(e, status, defaultError){
           //you can use the data from the elemenet and use the status do to stuff outsde the plugin
       },
   });


**** DONT FORGET TO LOG ALL IDEAS HERE 

PLUGIN CURRENTLY VALIDATES THE FOLLOWING 
-- If empty 
	- Input
	- textarea
	- select box (with chosen)
-- If valid email 
-- If telephone number 

*** UPDATE EACH TYPE OF VALIDATION THAT HAS BEEN INTRODUCED TO THE PLUGIN HERE


EXAMPLE:- 

data-val-type='["required", "number", "email"]'

$("#contactForm").validate({
    submitBtn: ".contact-submit",
    showGlobalMsg: false,
    scrollExclude: 100,
    onSubmit: function(){
        //submit form here
        console.log("submitted");
    }

});

*/


(function($){
    $.validate = function(el, options){
        var base = this;
        
        base.$el = $(el);
        base.el = el;

        base.valid = []; 
        base.globalMessageVisible = true;
        // this can be used to make sure the global message wont show on every blur      

        base.timer = null; 


        base.$el.data("validate", base);
        
        base.init = function(){
            base.options = $.extend({},$.validate.defaultOptions, options);   
            base.bindings();                         
        };

        base.bindings = function(){
            $(base.options.submitBtn).click(function(event){
                event.preventDefault();
                base.valid = []

                base.restAll();
                base.globalMessageVisible = true;

                base.$el.find("[data-val-type]").each(function(i, e){                    
                    base.validate(e);
                });

                if ($.inArray(false, base.valid) == -1){
                    base.options.onSubmit(base.el);
                }
            });

            base.$el.find("[data-val-type]").blur(function(){
                base.restInidivial(this);
                base.validate(this);
            });

            base.$el.find("[data-val-type]").focus(function(){
                base.globalMessageVisible = true;
                // base.validate(this);
                base.restInidivial(this);
            });

        };


/* =====================  All Validation Hapence here ===================================== */

        base.validate = function(e){            
            var e = $(e);
            var valTypes = e.data("val-type");

            $.each(valTypes, function(index, value){
                switch(value){
                    
                    case "required":
                        base.ifEmpty(e);
                        break;

                    case "phone":
                        base.ifRegex(e, base.regex("tele"), "tele");
                        break;

                    case "email":
                        base.ifRegex(e, base.regex("email"), "email");
                        break;

                    default:
                        console.log("Please assign a validation type");

                }
            });
            
        };

        base.ifEmpty = function(e){            
            var val = base.getVal(e);
            var defaultError = base.defaultError("empty");

            if(val == "" || val == null){
                base.valid.push(false);
                base.showErrors(e, defaultError);
            }
        };

        base.ifRegex = function(e, rgx, error){
            var val = base.getVal(e);
            var regex = rgx;

            if(e.attr("data-val-rgx")){
                regex = new RegExp(e.data("val-rgx")); // you have to access this as a regex try using data
            }

            if (!regex.test(val)){
                base.valid.push(false);
                base.showErrors(e, base.defaultError(error));
            }
        };

/* ========================================================================================= */

        base.showErrors = function(e, defaultError){
            // random ID not match errorbox with input
            base.setClass(false, e);      

            var errorID = Math.floor(Math.random()*3246) + new Date().getSeconds();                    
            e.attr('data-val-id', errorID);

            // if scroll to error is enabled
            if(base.options.scrollOnError){
                base.scrollToError(e);
            }
            
            // if Global Message should be shown
            if(base.options.showGlobalMsg){
                if(typeof base.options.customGlobalMessage == 'function'){
                    var status = base.valid;
                    base.options.customGlobalMessage(e, status);          
                }else{                    
                    base.showGlobalMsg(e, defaultError);    
                }
            }
            
            // Handleing how errors are shown (custom or native)
            if(typeof base.options.customErrorHandle == 'function'){
                var status = base.valid;
                base.options.customErrorHandle(e, status, defaultError);
            }else if(base.options.showErrors){
                base.defaultErrorHandle(e, defaultError, errorID);
            }else{
                // shall i console the errors here ?
            }  

        };
        

        base.defaultErrorHandle = function(e, defaultError, errorID){        
            var errorMsg = defaultError;

            if(e.attr("data-val-error")){
                errorMsg = e.attr("data-val-error");
            }                 

            base.appendErrorMsg(e, errorMsg, errorID);

        }; 

        base.appendErrorMsg = function(e, errorMsg, errorID){
            var errorBox = "<div class='default-error-box' data-error-id="+errorID+" style='display:none; "+base.options.errorBoxStyle+"'>"+errorMsg+"</div>"

            if(base.options.appendErrorBox == "after"){
                $(errorBox).insertAfter(e).fadeIn(base.options.speed);
            }else if(base.options.appendErrorBox == "before"){
                $(errorBox).insertBefore(e).fadeIn(base.options.speed);
            }
        }; 

        base.setClass = function(status, elm){            
            if(status){
                elm.removeClass(base.options.errorClass);
                elm.addClass(base.options.successClass);
            }else{
                elm.removeClass(base.options.successClass);
                elm.addClass(base.options.errorClass);
            }


        };

        base.showGlobalMsg = function(e, defaultError){
            // the parameters have been passed if we need it
            if(base.globalMessageVisible){
                base.globalMessageVisible = false;
                alert("Looks like you have some errors");
            }
        };

        base.scrollToError = function(e){
            var scrollAmt = e.offset().top - base.options.scrollExclude;
            $("html, body").stop().animate({scrollTop:scrollAmt}, base.options.speed);
        };

        // rest only works on the changes that the plugin made, wont work on any of the external functions 
        base.restAll = function(){
            base.$el.find(".default-error-box").remove();
            base.$el.find("[data-val-type]").removeClass(base.options.errorClass).removeClass(base.options.successClass);
        };

        base.restInidivial = function(e){

            var e = $(e);
            console.log(e);
            var errorID = e.attr('data-val-id');

            base.$el.find("[data-val-type]").removeClass(base.options.errorClass).removeClass(base.options.successClass);
            base.$el.find("[data-error-id='"+errorID+"']").remove();
        };  

        base.getVal = function(e){
            return $(e).val().trim();
        };  

        base.regex = function(type){
            switch(type){
                case 'email':
                return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                break;

                case 'tele':
                return /^(\d|\+|\(|\)|\-){1,8}(\d*\s*\-*\.*)*$/;
                break;
            }
        }       

        base.defaultError = function(error){
            switch(error){
                case 'empty':
                return "This field is required"
                break;

                case 'tele':
                return "Pleae enter a valid phone number"
                break;

                case 'email':
                return "Pleae enter a valid email address"
                break;

                default:
                return 'Ops, Looks like something went wrong, please try again'
            }
        };  

        // Run initializer
        base.init();
    };
    
    $.validate.defaultOptions = {
    	speed: 800,    	
        runOnBlur: true,
        allowTimeout: true,
        submitBtn: null, 
        // Define the submit button of the form

        scrollOnError: false, 
        // Define is you want the plugin to scroll to the error
        scrollExclude: 0,
        // defins a number (px) that you want to exclude from the scroll point


        showGlobalMsg: true,
        // This Decides wheather or not to use global messages

        customGlobalMessage: null,
        // This is an open function if the the userwants to take contole of the global message 
        // its a option function so you can show the message under the submit button or popup or any where



        customErrorHandle: null, 
        // the Custom Error Handle function can be fed from here, this will ignore the error handle method, the element is passed back to the function for the dev to use 


        
        showErrors: true,
        // if you want to only stop submittion and not show errors.



        showOutlines: true,
        // The plugin Outline the elements with errors if you dont want this set it to false 
        // NOTE if showErrors == false this will not run

        errorClass: "validation-error",
        successClass: "validation-success",
        // you can Define the colors of the 2 outlines, if you want only one type set the other to transparent 



        errorBoxStyle: "width:100%;",
        // if you want to style the error box you can just pass in the css you want
        // Error Box default Class 'default-error-box'



        appendErrorBox: "before",
        // after will insert the error box after the field 
        // before will insert the error box before the field 

        onSubmit: function(){},
    };
    
    $.fn.validate = function(options){
        return this.each(function(){
            (new $.validate(this, options));
        });
    };
    
})(jQuery);
