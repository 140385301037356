(function ($) {
    $.Xreq = function (el, options) {
        var base = this;

        base.$el = $(el);
        base.el = el;

        base.$el.data("Xreq", base);

        base.r = null;

        base.form = null;

        base.context = null;

        base.deferredQue = [];

        base.setOptions = function(opt){
            options = opt;
        };

        base.init = function () {
            base.options = $.extend({}, $.Xreq.defaultOptions, options);
            base.form = base.getForm();
            base.context = base.options.context ? base.options.context : null;
            base.req();
        };

        base.req = function () {
            base.options.onSubmit(base.$el, base.context);

            if (!base.options.allowMultipleRequests && base.r != null) {
                base.r.abort();
            }
            if ($.isFunction(base.options.deferredCallback)) {

                var d = $.Deferred();
                base.deferredQue.push(d);
                //console.log('add ',base.deferredQue.length);
                d.then(base.options.deferredCallback);
            }

            base.r = $.ajax({
                url: base.options.url,
                data: base.getData(),
                method: base.options.method,
                beforeSend: function () {
                    base.options.beforeSubmit(base.$el, base.context);
                },
                success: function (res) {
                    base.options.onSuccess(base.form, res, base.context);
                },
                error: function (res) {
                    base.options.onError(base.form, res.responseJSON, base.context, res.status);
                },
                complete: function (res) {

                    if (res.status == 301 && res.responseJSON.url) {
                        window.location = res.responseJSON.url;
                    }

                    base.options.onComplete(base.form, res, base.context);
                    if ($.isFunction(base.options.deferredCallback)) {
                        base.deferredQue.pop().resolve();
                    }
                    //base.deferredQue.resolved();
                    //console.log(base.deferredQue);
                },

            });
        };

        base.getForm = function () {
            return base.options.form ? $(base.options.form) : base.$el;
        };

        base.getData = function () {
            base.options.onPrepareData(base.$el);
            var data = Array();

            if (typeof base.form.is('form')) {
                data = base.form.serializeArray();
            }

            //LARAVEL CSRF fix
            data.push({name: '_token', value: $('#__token').attr('value')});

            if ($.isArray(base.options.data)) {
                $.each(base.options.data, function (index, nvpObject) {
                    if (typeof nvpObject === 'object')
                        data.push(nvpObject);
                });
            }
            else {
                if (typeof base.options.data === 'object')
                    data.push(base.options.data);
            }

            return data;
        };

        base.init();
    };

    $.Xreq.defaultOptions = {
        url: "",
        data: null,
        allowMultipleRequests: false,
        beforeSubmit: function () {
        },
        onSubmit: function () {
        },
        onSuccess: function (form, res) {
        },
        onError: function (form, res) {
        },
        onComplete: function (form, res) {
        },
        onPrepareData: function () {
        },
        method: "POST",
        form: null,
        deferredCallback: null,
    };

    $.fn.xreq = function (options) {
        return this.each(function () {
            if ($(this).data('Xreq')) {
                $(this).data('Xreq').setOptions(options);
                $(this).data('Xreq').init();
                return $(this);
            }
            (new $.Xreq(this, options));
        });
    };

})(jQuery);