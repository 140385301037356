(function($){
    $.Dscroll = function(el, options){


        var base = this;
        
        base.$el = $(el);
        base.el = el;
        
        base.$el.data("Dscroll", base);
        
        base.init = function(){
            base.options = $.extend({},$.Dscroll.defaultOptions, options);

            if( base.el === window)
            {
                base.scroller();
            }
            else
            {
                base.bindings();
            }
            
        };

        base.bindings = function(){
          base.$el.click(function(event) {
              base.scroller();
          }); 
        };

        base.scroller = function(){
            console.log(base.calculation());
            $("html, body").stop().animate({scrollTop:base.calculation()}, base.options.speed, 'swing', 
                function(){
                    base.options.onComplete(base.el);
                });
        };

        base.calculation = function(){
            var endPoint = base.options.endPoint;
            if($(endPoint).length == 1){
                var endPointTop = $(endPoint).offset().top;
                var exclude = base.options.exclude;
                var excludeAmt = 0;

                if($.isNumeric(exclude)){
                    excludeAmt = exclude;
                }else if(typeof exclude === "string"){
                    if($(exclude).length == 1){
                        excludeAmt = $("body").find(exclude).height();
                    }else{
                        base.repeatedElements(1);
                    }
                }

               var scrollAmt = endPointTop - excludeAmt - base.options.excludeExtra;
               return scrollAmt
            }else{
                base.repeatedElements(1);
            }
        };

        base.repeatedElements = function(msgNum){
            /*switch(msgNum){
                case 1: 
                throw "dip shit !";
                break;

                default: "you made a error EVEN i dont know, you dumn shit!";

            }*/
           
        };
        
        
        // Run initializer
        base.init();
    };

    $.Dscroll.toTop = function(speed, top){
        if(!speed){
            speed = 800;
        }

        if(!top){
            top = 0;
        }


       $("html, body").stop().animate({scrollTop:top}, speed, 'swing');
    };
    
    $.Dscroll.defaultOptions = {
        speed: 800,
        endPoint: null,
        exclude: 0,
        excludeExtra:0,
        onComplete: function(){}
    };
    
    $.fn.Dscroll = function(options){
        return this.each(function(){
            (new $.Dscroll(this, options));
        });
    };
    
})(jQuery);
